import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ReleaseNoteProvider } from '@shared-providers/beecloud/release-note.provider';
import { ReleaseNotesComponent } from '@shared-components/release-notes-component/release-notes.component';
import { LoginProvider } from '@shared-providers/beecloud/login.provider';
import { AuthenticationService } from './authentication.service';
import { firstValueFrom } from 'rxjs';

/**
 * The app initialisation service that handles all logic that should be ran/initialized
 * on start of the application & when the user is authenticated
 *
 * Is initialized in the SharedComponent
 */
@Injectable({
	providedIn: 'root',
})
export class SharedAppInitialisationService {
	constructor(
		private readonly modalController: ModalController,
		private readonly releaseNoteProvider: ReleaseNoteProvider,
		private readonly loginProvider: LoginProvider,
		private readonly authenticationService: AuthenticationService
	) {}

	/**
	 * A check whether the user is still active, based on the server not the client
	 * @returns whether or not the user is still active
	 */
	public async userIsActive(): Promise<boolean> {
		return new Promise(async (resolve) => {
			if (await this.authenticationService.isAuthenticated()) {
				this.loginProvider.isActive().subscribe((result) => {
					if (!result.data) {
						this.authenticationService.logout();
						resolve(false);
					}
					resolve(true);
				});
			} else resolve(false);
		});
	}

	/**
	 * A check whether the user, when consultant, has seen the latest
	 * release notes. If not open a model to show them.
	 * @returns Promise
	 */
	public async hasSeenLatestReleaseNotes(): Promise<void> {
		const hasLoggedInBefore = (await firstValueFrom(this.loginProvider.hasLoggedInBefore())).data;

		if (!hasLoggedInBefore) {
			return Promise.resolve();
		}

		return new Promise((resolve) => {
			this.releaseNoteProvider.hasSeenLatest().subscribe(async (result) => {
				if (!result.data.seen) {
					const modal = await this.modalController.create({
						component: ReleaseNotesComponent,
						canDismiss: false,
						cssClass: 'auto-height',
						componentProps: {
							notes: result.data.latest,
						},
					});
					void modal
						.onDidDismiss()
						.then(async () => {
							this.releaseNoteProvider.seenReleaseNote(result.data.latest.id).subscribe(() => {
								resolve();
							});
						})
						.catch();
					await modal.present();
				} else resolve();
			});
		});
	}
}
